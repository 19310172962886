<template>
  <a-modal
    :getContainer="getRefsAff"
    width="40.6vw"
    :visible="visible"
    @cancel="closeModal"
    class="fill-assignment"
  >
    <template #title>
      <strong class="modal-title">
        {{ title ? title : "填写成本信息" }}
      </strong>
    </template>
    <template #footer>
      <div class="footerBtn">
        <a-button @click="closeModal" class="cancelBtn">取消</a-button>
        <a-button @click="submitModal" type="primary" class="addEmpBtn">
          确定
        </a-button>
      </div>
    </template>
    <a-form
      layout="vertical"
      :model="formData"
      :rules="formRules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="formRef"
      class="org-form"
    >
      <a-row>
        <a-col :span="12">
          <a-form-item label="Cost Center" name="costCenter">
            <a-input v-model:value="formData.costCenter" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Cost Ctr" name="costCtr">
            <a-input v-model:value="formData.costCtr" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 1" name="orgOne">
            <a-input v-model:value="formData.orgOne" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 2" name="orgTwo">
            <a-input v-model:value="formData.orgTwo" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 3.1" name="orgThreeOne">
            <a-input v-model:value="formData.orgThreeOne" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 3.1.1" name="orgThreeOneOne">
            <a-input
                v-model:value="formData.orgThreeOneOne"
                placeholder="请输入"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 3.2" name="orgThreeTwo">
            <a-input v-model:value="formData.orgThreeTwo" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 4.1" name="orgFourOne">
            <a-input v-model:value="formData.orgFourOne" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 4.1.1" name="orgFourOneOne">
            <a-input v-model:value="formData.orgFourOneOne" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Demand Dept" name="demandDept">
            <a-input
                v-model:value="formData.demandDept"
                placeholder="请输入"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 4.2" name="orgFourTwo">
            <a-input v-model:value="formData.orgFourTwo" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 5" name="orgFive">
            <a-input v-model:value="formData.orgFive" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 5.1" name="orgFiveOne">
            <a-input v-model:value="formData.orgFiveOne" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 6.1" name="orgSixOne">
            <a-input v-model:value="formData.orgSixOne" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Org 6.2" name="orgSixTwo">
            <a-input v-model:value="formData.orgSixTwo" placeholder="请输入" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="Dept" name="dept">
            <a-select
                v-model:value="formData.dept"
                placeholder="选择 Dept"
                allowClear
            >
              <a-select-option
                  v-for="(item, index) in deptList"
                  :key="index"
                  :value="item"
              ><span :title="item">{{ item }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="ARVE Tag" name="arveTag">
            <a-select
                v-model:value="formData.arveTag"
                placeholder="选择 ARVE Tag"
                :options="arveTagList"
                allowClear
            >
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="CST Leader" name="cstLeader" class="form-item-inline">
            <a-select
                v-model:value="formData.cstLeader"
                placeholder="选择 CST Leader"
                allowClear
                show-search
            >
              <a-select-option
                  v-for="(item, index) in cstLeaderList"
                  :key="index"
                  :value="item"
              ><span :title="item">{{ item }}</span>
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import {computed, defineComponent, ref, onMounted} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "fillOrganization",
  props: {
    visible: Boolean,
    title: String,
    deptList: {
      type:Array
    },
    formState: {
      type: Object,
      default: () => ({
        costCenter: "",
        costCtr: "",
        orgOne: "",
        orgTwo: "",
        orgThreeOne: "",
        orgThreeOneOne: "",
        orgThreeTwo: "",
        orgFourOne: "",
        orgFourOneOne: "",
        demandDept: "",
        orgFourTwo: "",
        orgFive: "",
        orgFiveOne: "",
        orgSixOne: "",
        orgSixTwo: "",
        // rmOne: "",
        // rmTwo: "",
        // rmThree: "",
        dept: undefined,
        arveTag: undefined,
        cstLeader: "",
      }),
    },
  },
  emits: ["close-modal", "submit-modal"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formData = computed(() => props.formState);
    const formRef = ref();
    const arveTagList = ref([
      {
        value: 0,
        label: "N",
      },
      {
        value: 1,
        label: "Y",
      },
    ]);

    const formRules = {
      costCenter: [
        {
          required: true,
          message: "Please input Cost Center",
          trigger: "blur",
        },
      ],
      costCtr: [
        {
          required: true,
          message: "Please input Cost Ctr",
          trigger: "blur",
        },
      ],
      orgOne: [
        {
          required: true,
          message: "Please input Org 1",
          trigger: "blur",
        },
      ],
      orgTwo: [
        {
          required: true,
          message: "Please input Org 2",
          trigger: "blur",
        },
      ],
      orgThreeOne: [
        {
          required: true,
          message: "Please input Org 3.1",
          trigger: "blur",
        },
      ],
      orgThreeOneOne: [
        {
          required: true,
          message: "Please input Org 3.1.1",
          trigger: "blur",
        },
      ],
      orgThreeTwo: [
        {
          required: true,
          message: "Please input Org 3.2",
          trigger: "blur",
        },
      ],
      orgFourOne: [
        {
          required: true,
          message: "Please input Org 4.1",
          trigger: "blur",
        },
      ],
      orgFourOneOne: [
        {
          required: true,
          message: "Please input Org 4.1.1",
          trigger: "blur",
        },
      ],
      demandDept: [
        {
          required: true,
          message: "Please input Demand Dept",
          trigger: "blur",
        },
      ],
      orgFourTwo: [
        {
          required: true,
          message: "Please input Org 4.2",
          trigger: "blur",
        },
      ],
      orgFive: [
        {
          required: true,
          message: "Please input Org 5",
          trigger: "blur",
        },
      ],
      orgFiveOne: [
        {
          required: true,
          message: "Please input Org 5.1",
          trigger: "blur",
        },
      ],
      // rmOne: [
      //   {
      //     required: false,
      //     pattern: /^.*<([a-zA-Z.-])+@capgemini.com>\s*$/,
      //     message:
      //       "Please input the correct format RM 1.（Name <Email@capgemini.com>）",
      //     trigger: "blur",
      //   },
      // ],
      // rmTwo: [
      //   {
      //     required: false,
      //     pattern: /^.*<([a-zA-Z.-])+@capgemini.com>\s*$/,
      //     message:
      //       "Please input the correct format RM 2.（Name <Email@capgemini.com>）",
      //     trigger: "blur",
      //   },
      // ],
      // rmThree: [
      //   {
      //     required: false,
      //     pattern: /^.*<([a-zA-Z.-])+@capgemini.com>\s*$/,
      //     message:
      //       "Please input the correct format RM 3.（Name <Email@capgemini.com>）",
      //     trigger: "blur",
      //   },
      // ],
      arveTag: [
        {
          required: true,
          message: "Please select ARVE Tag",
          trigger: "change",
          type: "number",
        },
      ],
    };

    const closeModal = () => {
      formRef.value.resetFields();
      ctx.emit("close-modal");
    };
    const submitModal = () => {
      formRef.value.validate().then(() => {
        /*if (
          !formData.value.rmOne &&
          !formData.value.rmTwo &&
          !formData.value.rmThree
        ) {
          message.error("Please input at least one of RM 1, RM 2 and RM 3.");
          return;
        }*/
        ctx.emit("submit-modal");
      });
    };

    const getRefsAff = () => {
      let app = document.getElementById("main-page");
      if (app) {
        return app;
      }
    };

    const cstLeaderList = ref([]);
    const queryCstLeader = () => {
      $api.queryCstLeader().then((res) => {
        const cstLeader = res.data;
        cstLeaderList.value = cstLeader;
      });
    };
    onMounted(() => {
      queryCstLeader();
    });

    return {
      labelCol: { span: 22 },
      wrapperCol: { span: 22 },
      closeModal,
      submitModal,
      formData,
      formRef,
      formRules,
      getRefsAff,
      arveTagList,
      cstLeaderList,
      queryCstLeader,
    };
  },
});
</script>

<style lang="less">
.fill-assignment {
  width: 30vw;

  .ant-modal-body {
    max-height: 70vh;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }
}
</style>
