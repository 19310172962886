<template>
  <section class="org-list">
    <a-table
      ref="orgListRef"
      :columns="columns"
      :data-source="orgList"
      rowKey="id"
      :customRow="customRowFunc"
      :rowClassName="rowClassNameFunc"
      :scroll="{
        x: 1350,
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
      :row-selection="{
        selectedRows: selectedState.selectedList,
        selectedRowKeys: selectedState.selectedId,
        onSelect: onSelect,
        onChange: onSelectChange,
        onSelectAll: onSelectAll,
      }"
    >
    </a-table>
    <div class="message1" v-if="dataBoxShow">
      成本中心：
      <span>新增数据数:</span
      >{{ statusData.insertCount ? statusData.insertCount : 0 }}
      <span>, 更新数据数:</span
      >{{ statusData.updateCount ? statusData.updateCount : 0 }}
      <span>, 任务状态:</span
      >{{
        statusData.status == 1
            ? "进行中"
            : statusData.status == 2
                ? "已完成" : "已失败"
      }}
      <span>, 来源渠道:</span
      >{{ statusData.channel ? statusData.channel : "无" }}
      <span>, 操作人:</span
      >{{ statusData.operator ? statusData.operator : "无" }}
      <span>, 操作时间:</span
      >{{ statusData.operationTime ? statusData.operationTime : "无" }}
    </div>
    <div class="message2" v-if="noDataShow">
      成本中心：
      <span>新增数据数:</span
      >{{ statusData.insertCount ? statusData.insertCount : 0 }}
      <span>, 更新数据数:</span
      >{{ statusData.updateCount ? statusData.updateCount : 0 }}
      <span>, 任务状态:</span
      >{{
        statusData.status == 1
            ? "进行中"
            : statusData.status == 2
                ? "已完成" : "已失败"
      }}
      <span>, 来源渠道:</span
      >{{ statusData.channel ? statusData.channel : "无" }}
      <span>, 操作人:</span
      >{{ statusData.operator ? statusData.operator : "无" }}
      <span>, 操作时间:</span
      >{{ statusData.operationTime ? statusData.operationTime : "无" }}
    </div>
    <OrganizationInfo
      :visible="infoVisible"
      :orgInfo="activeRow"
      @close-info="closeInfo"
      @edit-click="editClick"
      @deactivate-click="deactivateClick"
      @enable-click="enableClick"
    />
    <FillOrganization
      v-model:visible="fillVisible"
      v-model:formState="formState"
      :dept-list="deptList"
      :title="fillTitle"
      @close-modal="closeModal"
      @submit-modal="submitModal"
    />
  </section>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import OrganizationInfo from "@/views/home/BaseDataManagement/Organization/components/OrganizationInfo";
import FillOrganization from "@/views/home/BaseDataManagement/Organization/components/FillOrganization";
import { message } from "ant-design-vue";
import { useTableHeight } from "@/utils/common";
import { formatTableCol } from "../../../../../utils/common";
import ModalBox from '@/components/ModalBox'

export default defineComponent({
  name: "OrganizationList",
  components: {
    FillOrganization,
    OrganizationInfo,
    ModalBox
  },
  emits: [
    "handleSelectedDate","ref-status",
  ],
  props: {
    nowStatus: String,
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();

    const orgListRef = ref();
    const columns = [
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        width: 130,
        fixed: "left",
        ellipsis: true,
      },
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        width: 290,
        fixed: "left",
        ellipsis: true,
      },
      {
        title: "Org 1",
        dataIndex: "orgOne",
        key: "orgOne",
        width: 130,
        ellipsis: true,
      },
      {
        title: "Org 2",
        dataIndex: "orgTwo",
        key: "orgTwo",
        width: 130,
        ellipsis: true,
      },
      {
        title: "Org 3.1",
        dataIndex: "orgThreeOne",
        key: "orgThreeOne",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 3.1.1",
        dataIndex: "orgThreeOneOne",
        key: "orgThreeOneOne",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 3.2",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 4.1",
        dataIndex: "orgFourOne",
        key: "orgFourOne",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 4.1.1",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Demand Dept",
        dataIndex: "demandDept",
        key: "demandDept",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 4.2",
        dataIndex: "orgFourTwo",
        key: "orgFourTwo",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 5",
        dataIndex: "orgFive",
        key: "orgFive",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 6.1",
        dataIndex: "orgSixOne",
        key: "orgSixOne",
        width: 240,
        ellipsis: true,
      },
      {
        title: "Org 6.2",
        dataIndex: "orgSixTwo",
        key: "orgSixTwo",
        width: 240,
        ellipsis: true,
      },
      /*{
        title: "RM 1",
        dataIndex: "rmOne",
        key: "rmOne",
        width: 280,
        ellipsis: true,
      },
      {
        title: "RM 2",
        dataIndex: "rmTwo",
        key: "rmTwo",
        width: 280,
        ellipsis: true,
      },
      {
        title: "RM 3",
        dataIndex: "rmThree",
        key: "rmThree",
        width: 280,
        ellipsis: true,
      },*/
      {
        title: "Dept",
        dataIndex: "dept",
        key: "dept",
        width: 240,
        ellipsis: true,
      },
      {
        title: "ARVE Tag",
        dataIndex: "arveTag",
        customRender: formatTableCol((text) => arveTagList[text]),
        width: 130,
        ellipsis: true,
      },
      {
        title: "CST Leader",
        dataIndex: "cstLeader",
        key: "cstLeader",
        width: 250,
        ellipsis: true,
        customRender: ({ text }) => (
          <div>
            <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
            <div>{text ? text.split(" ")[2] : null}</div>
          </div>

        ),
        customCell:() => {
            return {
              style: {
                lineHeight: 1.2,
                padding:'6px 10px',
              }
            }
          }
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const orgList = ref();
    const activeRow = ref({
      id: "",
    });
    const infoVisible = ref(false);
    let searchCondition = {};

    const customRowFunc = (record) => {
      return {
        onClick: () => {
          activeRow.value = record;
          infoVisible.value = true;
        },
      };
    };
    const rowClassNameFunc = (record) => {
      if (record.isDeleted) {
        if (record.id === activeRow.value.id) {
          return "active-row deactivate-row";
        }
        return "deactivate-row";
      }
      if (record.id === activeRow.value.id) {
        return "active-row";
      }
      return null;
    };

    const closeInfo = () => {
      activeRow.value = {
        id: "",
      };
      infoVisible.value = false;
    };

    const formatPersonInfo = (item, personList) => {
      personList.forEach((person) => {
        const index = item[person] ? item[person].indexOf("<") : -1;
        if (index !== -1) {
          item[person + "Name"] = item[person].substr(0, index);
          item[person + "Email"] = item[person].substr(index);
        }
      });
    };

    const doSearch = async () => {
      closeInfo();
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        queryCondition: searchCondition.condition,
        status: searchCondition.status,
      };
      await $api.getOrganizationList(params).then((res) => {
        pagination.total = res.data.total;
        orgList.value = res.data.resource;
        orgList.value.forEach((item) => {
          formatPersonInfo(item, ["rmOne", "rmTwo", "rmThree", "costCtr"]);
        });
        if (res.data.total === 0) {
          dataBoxShow.value = false;
          noDataShow.value = true;
        } else {
          dataBoxShow.value = true;
          noDataShow.value = false;
        }
      });
      await getState()
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const arveTagList = ["N", "Y"];
    const fillVisible = ref(false);
    const fillTitle = ref("");
    const deptList = ref([]);
    const formState = ref({
      costCenter: "",
      costCtr: "",
      orgOne: "",
      orgTwo: "",
      orgThreeOne: "",
      orgThreeOneOne: "",
      orgThreeTwo: "",
      orgFourOne: "",
      orgFourOneOne: "",
      demandDept: "",
      orgFourTwo: "",
      orgFive: "",
      orgFiveOne: "",
      orgSixOne: "",
      orgSixTwo: "",
      rmOne: "",
      rmTwo: "",
      rmThree: "",
      dept: undefined,
      arveTag: undefined,
      cstLeader: undefined,
    });
    const addClick = () => {
      fillTitle.value = "新增成本信息";
      fillVisible.value = true;
      formState.value = {
        costCenter: "",
        costCtr: "",
        orgOne: "",
        orgTwo: "",
        orgThreeOne: "",
        orgThreeOneOne: "",
        orgThreeTwo: "",
        orgFourOne: "",
        orgFourOneOne: "",
        demandDept: "",
        orgFourTwo: "",
        orgFive: "",
        orgFiveOne: "",
        orgSixOne: "",
        orgSixTwo: "",
        rmOne: "",
        rmTwo: "",
        rmThree: "",
        dept: undefined,
        arveTag: undefined,
        cstLeader: undefined,
      };
    };
    const editClick = () => {
      fillTitle.value = "编辑成本信息";
      Object.assign(formState.value,
        { ...activeRow.value, ...{ createOperator: undefined, createTime: undefined, operationTime: undefined, operator: undefined } });
      fillVisible.value = true;
    };
    const closeModal = () => {
      formState.value = {};
      fillVisible.value = false;
    };
    const submitModal = () => {
      // formState.value.operationTime =
      //   formState.value.operationTime.operationTime.format("YYYY-MM-DD");
      // formState.value.createTime =
      //   formState.value.createTime.format("YYYY-MM-DD");
      $api.saveOrganization(formState.value).then((res) => {
        closeModal();
        doSearch(searchCondition);
        message.success(res.msg);
      });
    };
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const flag = ref()
    const handleOk = () => {
      switch (flag.value) {
        case 1:
          $api.deactivateOrganization({ id: activeRow.value.id }).then((res) => {
            if(res.msg === '停用失败'){
              message.error(res.data)
            } else {
              closeModal();
              doSearch(searchCondition);
              cancelSelectedData()
              emit("handleSelectedDate", selectedState);
              emit('ref-status')
              message.success(res.msg);
            }
          });
          break
        case 2:
          $api.enableOrganization({ id: activeRow.value.id }).then((res) => {
            closeModal();
            doSearch(searchCondition);
            cancelSelectedData()
            emit("handleSelectedDate", selectedState);
            emit('ref-status')
            message.success(res.msg);
          });
          break
        default:
            break
      }
    }
    const deactivateClick = () => {
      content.value = '是否确定停用，停用后对应人员的数据权限也同步取消。'
      showIconCom.value = 'ExclamationCircleOutlined'
      flag.value = 1
      modelValue.value = true

      /*Modal.confirm({
        title: () => '提示',
        content: "是否确定停用，停用后对应人员的数据权限也同步取消。",
        width: 350,
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          $api.deactivateOrganization({ id: activeRow.value.id }).then((res) => {
            if(res.msg === '停用失败'){
              message.error(res.data)
            } else {
              closeModal();
              doSearch(searchCondition);
              cancelSelectedData()
              emit("handleSelectedDate", selectedState);
              emit('ref-status')
              message.success(res.msg);
            }
          });
        }
      })*/
    };
    const enableClick = () => {
      content.value = '是否确定启用，启用后对应人员的数据权限也同步恢复。'
      showIconCom.value = 'ExclamationCircleOutlined'
      flag.value = 2
      modelValue.value = true

      /*Modal.confirm({
        title: () => '提示',
        content: "是否确定启用，启用后对应人员的数据权限也同步恢复。",
        width: 350,
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          $api.enableOrganization({ id: activeRow.value.id }).then((res) => {
            closeModal();
            doSearch(searchCondition);
            cancelSelectedData()
            emit("handleSelectedDate", selectedState);
            emit('ref-status')
            message.success(res.msg);
          });
        }
      })*/
    };
    const tableHeight = ref(0);
    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    const getDeptList = () => {
      const params = {
        dictBak1: 0,
      };
      $api.skillsComboBox(params).then((res) => {
        deptList.value = res.data;
      });
    };

    const selectedState = reactive({
      selectedId: [],
      selectedList: [],
    });

    const onSelectChange = (selectedRowKeys) => {
      selectedState.selectedId = selectedRowKeys;
      emit("handleSelectedDate", selectedState);
    };

    const onSelect = (record, selected) => {
      if (selected) {
        selectedState.selectedList.push(record);
      } else {
        const delIndex = selectedState.selectedList.findIndex((val) => {
          return val.id === record.id;
        });
        selectedState.selectedList.splice(delIndex, 1);
      }
      emit("handleSelectedDate", selectedState);
    };
    const onSelectAll = (selected,selectedRowKeys,selectedchange) => {
      if (selected) {
        selectedState.selectedList.push(...selectedRowKeys);
      } else {
        selectedState.selectedList = selectedState.selectedList.filter(item => {
          return !selectedchange.map(i => i.id).includes(item.id);
        })
      }
      emit("handleSelectedDate", selectedState);
    }

    const cancelSelectedData = () => {
      selectedState.selectedId = []
      selectedState.selectedList = []
    }
    const statusData = ref({})
    const noDataShow = ref(false);
    const dataBoxShow = ref("");
    const getState = () => {
      $api.organizationsStatus().then((res) => {
        statusData.value = res.data;
      });
    }

    onMounted(() => {
      getSize();
      getDeptList();
      getState()
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      orgListRef,
      columns,
      pagination,
      orgList,
      activeRow,
      customRowFunc,
      infoVisible,
      closeInfo,
      searchClick,
      doSearch,
      fillVisible,
      fillTitle,
      formState,
      addClick,
      editClick,
      closeModal,
      submitModal,
      deactivateClick,
      enableClick,
      rowClassNameFunc,
      tableHeight,
      arveTagList,
      getDeptList,
      deptList,
      modelValue,
      content,
      showIconCom,
      handleOk,
      selectedState,
      onSelectChange,
      onSelect,
      cancelSelectedData,
      onSelectAll,
      statusData,
      dataBoxShow,
      noDataShow,
      getState,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  >tr>th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  >tr>td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);
  position: relative;

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }

  .message1 {
    position: absolute;
    bottom: 5px;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }

  .message2 {
    position: absolute;
    bottom: -1.5vw;
    height: 20px;
    line-height: 20px;
    color: #6f6f6f;
    span {
      color: #b3b3b3;
    }
  }
}

:deep(.ant-table-body) {
  .active-row {
    background: #e8f4ff !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e8f4ff !important;
    }
  }

  .deactivate-row {
    background: #f4f4f4 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #f4f4f4 !important;
    }
  }

  .deactivate-row.active-row {
    background: #e0e0e0 !important;

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right,
    td {
      background: #e0e0e0 !important;
    }
  }
}
</style>
