<template>
  <div class="org-info" v-show="visible">
    <CloseOutlined class="close-icon" @click="cancel" />
    <div class="title">成本中心</div>
    <div class="btn-part">
      <a-space>
        <a-space v-if="hasBtn">
          <a-button
            type="primary"
            @click="editClick"
            v-if="!orgInfo.isDeleted"
            v-auth="['system:organization:edit']"
          >
            编辑
          </a-button>
          <a-button
            type="primary"
            @click="deactivateClick"
            v-if="!orgInfo.isDeleted"
            v-auth="['system:organization:deactivate']"
          >
            停用
          </a-button>
          <a-button
            type="primary"
            @click="enableClick"
            v-if="orgInfo.isDeleted"
            v-auth="['system:organization:enable']"
          >
            启用
          </a-button>
        </a-space>

        <a-button type="primary" @click="recordClick" v-auth="['system:organization:record']">
          变更记录
        </a-button>
      </a-space>
    </div>
    <div class="scroll-wrap">
      <a-row>
        <a-col :span="24" class="label"> Cost Center </a-col>
        <a-col :span="24" class="value-high">
          {{ orgInfo.costCenter }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" class="label"> Cost Ctr </a-col>
        <a-col :span="24" class="value-high">
          {{ orgInfo.costCtr }}
        </a-col>
      </a-row>

      <div class="line"></div>

      <a-row>
        <a-col :span="7" class="label"> Org 1 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgOne }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 2 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgTwo }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 3.1 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgThreeOne }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 3.1.1 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgThreeOneOne }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 3.2 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgThreeTwo }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 4.1 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgFourOne }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 4.1.1 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgFourOneOne }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Demand Dept </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.demandDept }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 4.2 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgFourTwo }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 5 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgFive }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 5.1 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgFiveOne }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 6.1 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgSixOne }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Org 6.2 </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.orgSixTwo }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7" class="label"> Dept </a-col>
        <a-col :span="17" class="value-normal">
          {{ orgInfo.dept }}
        </a-col>
      </a-row>

      <div class="line"></div>

      <!--      <a-row>
        <a-col :span="24" class="label"> RM 1 </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.rmOneName }}
        </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.rmOneEmail }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" class="label"> RM 2 </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.rmTwoName }}
        </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.rmTwoEmail }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" class="label"> RM 3 </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.rmThreeName }}
        </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.rmThreeEmail }}
        </a-col>
      </a-row>-->
      <a-row>
        <a-col :span="24" class="label"> ARVE Tag </a-col>
        <a-col :span="24" class="value-low">
          {{ arveTagList[orgInfo.arveTag] }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" class="label"> CST Leader </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.cstLeader }}
        </a-col>
      </a-row>

      <div class="line"></div>

      <a-row>
        <a-col :span="24" class="label"> 操作人 </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.operator }}
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" class="label"> 操作时间 </a-col>
        <a-col :span="24" class="value-low">
          {{ orgInfo.operationTime }}
        </a-col>
      </a-row>
    </div>
  </div>
  <ChangeRecordModal
    v-model:visible="recordVisible"
    title="查看组织变更记录"
    @close-modal="closeRecordModal"
    :info="orgInfo"
    :id="orgInfo.id"
  />
</template>

<script>
import { defineComponent, ref } from "vue";
import ChangeRecordModal from "@/components/ChangeRecordModal";
import {
  CloseOutlined,
} from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    ChangeRecordModal,
    CloseOutlined,
  },
  props: {
    visible: Boolean,
    orgInfo: Object,
    hasBtn: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close-info", "edit-click", "deactivate-click"],
  setup(props, ctx) {
    const arveTagList = ["N", "Y"];
    const cancel = () => {
      ctx.emit("close-info");
    };
    const editClick = () => {
      ctx.emit("edit-click");
    };
    const deactivateClick = () => {
      ctx.emit("deactivate-click");
    };
    const enableClick = () => {
      ctx.emit("enable-click");
    };

    const recordVisible = ref(false);
    const recordClick = () => {
      recordVisible.value = true;
    };
    const closeRecordModal = () => {
      recordVisible.value = false;
    };

    return {
      cancel,
      editClick,
      deactivateClick,
      enableClick,
      recordClick,
      closeRecordModal,
      recordVisible,
      arveTagList,
    };
  },
});
</script>

<style lang="less" scoped>
.org-info {
  position: relative;
  padding: 20px 0 20px 20px;
  display: flex;
  flex-direction: column;
  width: 25%;

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 22px;
    line-height: 22px;
  }

  .title {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8d8d8d;
    margin-bottom: 5px;
  }

  .btn-part {
    margin-bottom: 5px;
  }

  .ant-row {
    margin-top: 5px;
  }

  .label {
    font-size: 12px;
    line-height: 14px;
    color: #8d8d8d;
    margin-bottom: 5px;
  }

  .value-high {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 5px;
  }

  .value-normal {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #505050;
    margin-bottom: 5px;
  }

  .value-low {
    font-size: 12px;
    line-height: 14px;
    color: #505050;
    margin-bottom: 5px;
  }

  .line {
    border-bottom: 1px solid #d9d9d9;
    width: 100%;
    margin: 5px 0;
  }

  .scroll-wrap {
    flex: 1;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      width: 10px;
      height: 10px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }
}
</style>
