<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="搜索" name="condition" class="rms-form-item">
              <a-input
                  v-model:value="formState.condition"
                  placeholder="Org 3.2/Org 4.2/Cost Ctr"
                  enter-button
                  allowClear
                  autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="是否停用" name="status" class="rms-form-item">
              <a-select
                  v-model:value="formState.status"
                  :options="statusOps"
                  placeholder="请选择"
                  allowClear
              >
              </a-select>
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <a-button class="" type="primary" ghost @click="batchDeactive" :disabled="(formState.status && nowStatus !== 'all')? (nowStatus === 'Deactivate') : (selectedPropState.selectedId.length === 0 || !selectedPropState.selectedList.every((item) => item.isDeleted === 0))" v-auth="['system:organization:batchDeactive']">
              批量停用
            </a-button>
            <a-button class="ml12" type="primary" ghost @click="batchActive" :disabled="(formState.status && nowStatus !== 'all')? (nowStatus === 'Enable'):(selectedPropState.selectedId.length === 0 || !selectedPropState.selectedList.every((item) => item.isDeleted === 1))" v-auth="['system:organization:batchActive']">
              批量启用
            </a-button>
            <Tooltip title="导出">
              <a-button class="ml12" type="primary" ghost @click="exportClick" v-auth="['system:organization:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="下载模板">
              <a-button class="ml12" type="primary" ghost @click="downloadClick" v-auth="['system:organization:download']">
                <DownloadOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="导入">
              <a-button class="ml12" type="primary" ghost @click="uploadClick" v-auth="['system:organization:import']">
                <ImportOutlined />
              </a-button>
            </Tooltip>
            <Tooltip title="新增">
              <a-button class="ml12" type="primary" @click="addClick" v-auth="['system:organization:add']">
                <PlusOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>
<script>
import {defineComponent, reactive, ref, onActivated, watch} from "vue";
import {cloneDeep} from "lodash";
import {
  DownloadOutlined,
  PlusOutlined,
  ExportOutlined,
  ImportOutlined,
} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
import {useGlobalPropertyHook} from "@/hooks/common";
import ModalBox from "@/components/ModalBox.vue";

import Tooltip from '@/components/Tooltip'
export default defineComponent({
  name: "TopFilter",
  components: {
    ModalBox,
    DownloadOutlined,
    PlusOutlined,
    ExportOutlined,
    ImportOutlined,
    Tooltip
  },
  emits: [
    "search",
    "add-click",
    "export-click",
    "download-click",
    "upload-click",
    "resetTable",
  ],
  props: {
    nowStatus: String,
    selectedState: {
      type: Object,
      default: () => {
        return {
          selectedId: [],
          selectedList: [],
        }
      },
    },
  },
  setup(props, ctx) {
    const formRef = ref();
    const { $api } = useGlobalPropertyHook();
    const statusOps = ref([
      {
        value: "0",
        label: "启用",
      },
      {
        value: "1",
        label: "停用",
      },
    ]);

    const resetForm = () => {
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
      ctx.emit("cancelSelected");
    };
    let currentState  = {}
    const updateParams =() => {
      currentState = cloneDeep(formState);
    }
    const formState = reactive({
      status: undefined,
      condition: "",
      cstLeader: "",
    });
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    const addClick = () => {
      ctx.emit("add-click");
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };
    const downloadClick = () => {
      ctx.emit("download-click");
    };
    const uploadClick = () => {
      ctx.emit("upload-click");
    };
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const flag = ref()
    const handleOk = () => {
      switch (flag.value) {
        case 1:
          $api.batchLogicalDeletion(selectedPropState.selectedId).then((res) => {
            if(res.msg === '停用失败'){
              message.error(res.data)
            } else {
              resetForm()
              message.success("操作成功")
            }
          });
          break
        case 2:
          $api.batchLogicalEnable(selectedPropState.selectedId).then(() => {
            resetForm()
            message.success("操作成功")
          });
          break
        default:
          break
      }
    }
    const batchActive = () => {
      if(selectedPropState.selectedId.length === 0){
        message.error('请先选择成本中心数据')
      } else {
        content.value = '是否确定启用，启用后对应人员的数据权限也同步恢复。'
        showIconCom.value = 'ExclamationCircleOutlined'
        flag.value = 2
        modelValue.value = true
      }
    }
    const batchDeactive = () => {
      if(selectedPropState.selectedId === 0){
        message.error('请先选择成本中心数据')
      } else {
        content.value = '是否确定停用，停用后对应人员的数据权限也同步取消。'
        showIconCom.value = 'ExclamationCircleOutlined'
        flag.value = 1
        modelValue.value = true
      }
    }
    const selectedPropState = reactive({
      selectedId:  [],
      selectedList: [],
    });
    watch(
        () => props.selectedState,
        () => {
          selectedPropState.selectedList = props.selectedState? props.selectedState.selectedList : []
          selectedPropState.selectedId = props.selectedState? props.selectedState.selectedId : []
          console.log(selectedPropState)
        },
        {deep:true}
    )

    onActivated(() => {
      ctx.emit("resetTable");
    });

    return {
      formState,
      statusOps,
      formRef,
      doSearch,
      resetForm,
      addClick,
      exportClick,
      downloadClick,
      uploadClick,
      updateParams,
      batchActive,
      batchDeactive,
      selectedPropState,
      modelValue,
      handleOk,
      content,
      showIconCom,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
